import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import _ from "lodash";
import QRCode from "qrcode.react";

const stringifyModifiers = (items) => {
  const grouped = _.groupBy(items.map((i) => i.list_name));
  return Object.keys(grouped)
    .map((name) => {
      const count = grouped[name].length;
      return count > 1 ? `${name}\u00A0(x${count})` : name;
    })
    .join(", ");
};

const capPrice = (number) => Math.round(number * 100) / 100;

const getTableStyle = (fontSize, width) => ({
  width,
  color: "black",
  fontFamily: "sans-serif",
  fontSize,
});

const tdTitleStyle = (scale = 1) => ({
  borderTop: "3px solid black",
  borderBottom: "3px solid black",
  textAlign: "center",
  fontSize: 22 * scale,
  overflow: "hidden",
});

const tdHeaderStyle = (scale = 1) => ({
  borderBottom: "2px dashed black",
  textAlign: "center",
  fontSize: 18 * scale,
  overflow: "hidden",
});

const tdFooterStyle = {
  borderTop: "1px dashed black",
  textAlign: "center",
};

const bigCheckNumberStyle = (scale = 1) => ({
  borderBottom: "1px dashed black",
  textAlign: "center",
  fontSize: 35 * scale,
  fontWeight: 800,
  padding: "15px 0",
});

const tdSeparatorStyle = {
  padding: 0,
  borderBottom: "1px solid black",
};

const tdInfoStyle = {
  paddingTop: 15,
  paddingBottom: 15,
};

const tdInfoDeliveryStyle = {
  paddingTop: 5,
  paddingBottom: 5,
  borderTop: "1px solid black",
};

const smallTextStyle = (scale = 1) => ({
  fontSize: 12 * scale,
  margin: 0,
});

const servicePercentStyle = {
  padding: "0 3px 0 18px",
};

const ORDER_TYPES = {
  IN_STORE: "in_store",
  DELIVERY: "delivery",
  PICKUP: "pickup",
};

const ORDER_PAYMENT_TYPE = {
  PAYMENT: "payment",
  REFUND: "refund",
};

const CheckHeader = ({ order, checkSettings, lang, scale }) => (
  <>
    <tr>
      <td style={tdTitleStyle(scale)} colSpan="3">{checkSettings.name}</td>
    </tr>
    {checkSettings.field_top && (
      <tr>
        <td style={tdHeaderStyle(scale)} colSpan="3">{checkSettings.field_top}</td>
      </tr>
    )}
    {checkSettings.number_display_type === "big" && (
      <tr>
        <td style={bigCheckNumberStyle(scale)} colSpan="3">{order.display_number}</td>
      </tr>
    )}
    <tr>
      <td style={tdInfoStyle} colSpan="3">
        <span>
          {order.type !== ORDER_TYPES.IN_STORE && (
            <>
              <span>{lang.header.type}: {lang.orderTypes[order.type]}</span>
              <br />
            </>
          )}
          <span>#{order.id}</span>
          <br />
          <span>{moment(order.time).format("L")} {moment(order.time).format("LT")}</span>
          {checkSettings.number_display_type === "small" && (
            <span style={{ float: "right" }}>{order.display_number}</span>
          )}
        </span>
      </td>
    </tr>
  </>
);

CheckHeader.propTypes = {
  order: PropTypes.object.isRequired,
  checkSettings: PropTypes.object.isRequired,
  scale: PropTypes.number.isRequired,
  lang: PropTypes.object.isRequired,
};

const CheckBody = ({ orderLines, lang, scale }) => (
  <>
    <tr>
      <td>
        <b>{lang.table.name}</b>
      </td>
      <td align="center">
        <b>{lang.table.quantity}</b>
      </td>
      <td align="right">
        <b>{lang.table.amount}</b>
      </td>
    </tr>
    {orderLines.map((orderLine) => (
      <tr key={orderLine.id}>
        <td align="left">
          {orderLine.display_name}
          {orderLine.modifiers?.filter((m) => m.value.filter((v) => v.price > 0).length > 0)
            .map(({ modifier_group_id, list_group_name, value }) => (
              <div key={modifier_group_id} style={{ ...smallTextStyle(scale), marginLeft: 10 }}>
                {list_group_name}: {stringifyModifiers(value.filter((v) => v.price > 0))}
              </div>
            ))}
        </td>
        <td align="center" style={{ verticalAlign: "top" }}>{orderLine.count}</td>
        <td align="right" style={{ verticalAlign: "top" }}>{capPrice(orderLine.total_price)}</td>
      </tr>
    ))}
  </>
);

CheckBody.propTypes = {
  orderLines: PropTypes.array.isRequired,
  scale: PropTypes.number.isRequired,
  lang: PropTypes.object,
};

const paymentMethodLabel = (payment_method, lang) => (payment_method.type === "custom"
  ? payment_method.name : lang.paymentMethods[payment_method.name]);

const Payments = ({ payments, paymentMethods, lang }) => {
  const amountsByMethod = _(payments)
    .groupBy((payment) => {
      const paymentMethod = paymentMethods.find((p) => p.id === payment.payment_method_id);
      const paymentMethodName = paymentMethodLabel(paymentMethod, lang);
      return (payment.type === ORDER_PAYMENT_TYPE.PAYMENT
        ? paymentMethodName : `${lang.footer.refund} ${paymentMethodName}`);
    })
    .mapValues((val) => _.sumBy(val, "amount"))
    .value();

  return Object.keys(amountsByMethod).map((method) => (
    <tr key={method}>
      <td>{method}</td>
      <td />
      <td align="right">
        <b>{capPrice(amountsByMethod[method])}</b>
      </td>
    </tr>
  ));
};

Payments.propTypes = {
  payments: PropTypes.array.isRequired,
  lang: PropTypes.object,
};

const CheckFooter = ({ order, checkSettings, paymentMethods, lang, scale }) => {
  const lastOpu = _.last(order.order_price_updates);
  return (
    <>
      <tr>
        <td>
          {lang.footer.orderTotal}
        </td>
        <td />
        <td align="right">
          {capPrice(lastOpu.order_lines_total)}
        </td>
      </tr>
      {parseFloat(lastOpu.discount) > 0 && (
      <>
        <tr>
          <td>
            {lang.footer.discount}
          </td>
          <td style={servicePercentStyle}>
            {lastOpu.discount * 100}%
          </td>
          <td align="right">
            {capPrice(lastOpu.order_lines_total * lastOpu.discount)}
          </td>
        </tr>
        <tr>
          <td>
            {lang.footer.orderTotalWithDiscount}
          </td>
          <td />
          <td align="right">
            {capPrice(lastOpu.order_lines_total - (lastOpu.discount * lastOpu.order_lines_total))}
          </td>
        </tr>
      </>
      )}
      {parseFloat(lastOpu.service_percent) > 0 && (
      <>
        <tr>
          <td>
            {lang.footer.servicePercent}
          </td>
          <td style={servicePercentStyle}>
            {lastOpu.service_percent * 100}%
          </td>
          <td align="right">
            {capPrice(lastOpu.order_lines_total * lastOpu.service_percent)}
          </td>
        </tr>
      </>
      )}
      {order.delivery_info && (
      <tr>
        <td>
          {lang.footer.deliveryPrice}
        </td>
        <td />
        <td align="right">
          {capPrice(order.delivery_info.delivery_price)}
        </td>
      </tr>
      )}
      <tr>
        <td>
          <b>{lang.footer.toPay}</b>
        </td>
        <td />
        <td align="right">
          <b>{capPrice(lastOpu.list_price)}</b>
        </td>
      </tr>
      <Payments payments={order.order_payments} paymentMethods={paymentMethods} lang={lang} />
      {checkSettings.field_bottom && (
        <tr>
          <td style={tdFooterStyle} colSpan="3">{checkSettings.field_bottom}</td>
        </tr>
      )}
      {checkSettings.print_delivery_info && order.delivery_info && (
        <tr>
          <td style={tdInfoDeliveryStyle} colSpan="3">
            <span>
              {lang.footer.street}: {order.delivery_info.address.street}
            </span>
            {order.delivery_info.address.house && (
              <>
                <br />
                <span>
                  {lang.footer.house}: {order.delivery_info.address.house}
                </span>
              </>
            )}
            {order.delivery_info.address.flat && (
              <>
                <br />
                <span>
                  {lang.footer.flat}: {order.delivery_info.address.flat}
                </span>
              </>
            )}
            <br />
            <span>
              {lang.footer.phone}: {order.customer.phone_number}
            </span>
            {order.customer.name && (
              <>
                <br />
                <span>
                  {lang.footer.name}: {order.customer.name}
                </span>
              </>
            )}
            {order.delivery_info.address.notes && (
              <p style={smallTextStyle(scale)}>
                {lang.footer.note}: {order.delivery_info.address.notes}
              </p>
            )}
            <p style={smallTextStyle(scale)}>
              {lang.footer.paymentMethod}: {paymentMethodLabel(
                paymentMethods.find((p) => p.id === order.delivery_info.payment_method_id),
                lang,
              )}
              {order.delivery_info.cash_return > 0
                && ` (${lang.footer.cashReturn} ${order.delivery_info.cash_return} ${lang.currency})`}
            </p>
          </td>
        </tr>
      )}
      {checkSettings.print_order_notes && (
        <tr>
          <td>
            <i>{order.notes}</i>
          </td>
        </tr>
      )}
    </>
  );
};

CheckFooter.propTypes = {
  order: PropTypes.object.isRequired,
  checkSettings: PropTypes.object.isRequired,
  paymentMethods: PropTypes.array.isRequired,
  scale: PropTypes.number.isRequired,
  lang: PropTypes.object,
};

const QRCodeCheck = ({ checkSettings: { qr_content, qr_description }, size }) => (
  <tr style={{ textAlign: "center" }}>
    <td style={{ padding: "15px 0" }} colSpan="3">
      <QRCode width={size} height={size} value={qr_content} renderAs="svg" />
      <br />
      {qr_description && qr_description.length > 0 && (
        <span>
          {qr_description}
        </span>
      )}
    </td>
  </tr>
);

QRCodeCheck.propTypes = {
  checkSettings: PropTypes.object.isRequired,
  size: PropTypes.number.isRequired,
};

const Check = ({ order, checkSettings, paymentMethods, width = 274, lang }) => (
  <table id="check" style={getTableStyle(checkSettings.font_size * (width / 274), width)}>
    <tbody>
      <CheckHeader lang={lang} checkSettings={checkSettings} order={order} scale={width / 274} />
      <tr>
        <td style={tdSeparatorStyle} colSpan="3" />
      </tr>
      <CheckBody
        lang={lang}
        orderLines={order.order_lines.filter((ol) => ol.status !== "cancelled")}
        scale={width / 274}
      />
      <tr>
        <td style={tdSeparatorStyle} colSpan="3" />
      </tr>
      <CheckFooter
        lang={lang}
        checkSettings={checkSettings}
        paymentMethods={paymentMethods}
        order={order}
        scale={width / 274}
      />
      {checkSettings.qr_content && (
        <QRCodeCheck checkSettings={checkSettings} size={width / 2.5} />
      )}
    </tbody>
  </table>
);

Check.propTypes = {
  order: PropTypes.object.isRequired,
  checkSettings: PropTypes.object.isRequired,
  paymentMethods: PropTypes.array.isRequired,
  lang: PropTypes.object.isRequired,
  width: PropTypes.number,
};

export default Check;
